body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: #6e6e6e;
  outline: #333 solid 1px;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

@media screen and (min-width: 767px) {
  .side-space {
    width: 69%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 767px) {
  .mobile-hidden {
    visibility: hidden;
    height: 0;
  }
}
