.safe-area {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding-top: env(safe-area-inset-top, 0);
  padding-bottom: env(safe-area-inset-bottom, 0);
}

.custom-scroll {
  scrollbar-color: black transparent;
  scrollbar-width: thin;
}

.settings-bar {
  margin-top: 0;
  margin-bottom: auto;
}

.input-style {
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  padding: 3px;
}

.input-style:focus-within {
  outline: none;
  transition: 0.1s;
  box-shadow: 0 0 1pt 1pt grey;
}

input {
  flex-grow: 2;
  border: none;
}

input:focus {
  outline: none;
}

textarea {
  flex-grow: 2;
  border: none;
}

textarea:focus {
  outline: none;
}
